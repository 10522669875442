import {useEffect} from 'react';

import {isBrowser} from '@fo/shared/utils/browserSupport';

function CookieFirstLoader(): null {
  useEffect(() => {
    if (!isBrowser) {
      return;
    }
    const onPageLoad = () => {
      window.removeEventListener('load', onPageLoad);
      const head = document.querySelector('head');
      const script = document.createElement('script');
      script.src = process.env.COOKIE_FIRST_SCRIPT_URL ?? '';
      head?.appendChild(script);
    };
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad, false);
    }
  }, []);

  return null;
}

export default CookieFirstLoader;

import {PRODUCTION, STAGING} from './environments';

export interface Flags {
  BLOG_SEARCH: boolean;
  CASHPLUS_QUESTIONS: boolean;
  BRS_NEW_USER_REDIRECT_AND_PREFILL: boolean;
  BRS_REFERRAL_COOKIE: boolean;
  DASHBOARD_ALL_APPLICATIONS: boolean;
  KNOWLEDGE_HUB_SEARCH: boolean;
  MODAL_BUSINESS_SERVICES_CTA: boolean;
  PARTNERS_USE_FORM: boolean;
  PRODUCT_CARD_V2: boolean;
  PRODUCT_CARD_V2_MONTHLY_REPAYMENTS: boolean;
  QUOTE_FORM_PARTIAL_APPLICATIONS: boolean;
  QUOTE_FORM_PROPERTY_IS_KNOWN_ADDRESS: boolean;
  SEND_VERIFY_EMAIL: boolean;
  FPSP_FIRST_TIME_LOADING: boolean;
  OPEN_BANKING_DROP_OFF_NOTIFICATION: boolean;
  OPEN_BANKING_MANDATORY_REGULAR_FLOW: boolean;
  OPEN_BANKING_MANDATORY_ON_QUERY_PARAM: boolean;
  OPEN_BANKING_LIGHT_AND_FULL_VIEWS: boolean;
  OPEN_ACCOUNTING: boolean;
  TIDE_CROSS_BUSINESS_SERVICES: boolean;
  TURNOVER_FIELD_UPDATE: boolean;
  ENABLE_BUSINESS_SERVICE_CARD: boolean;
  ENABLE_CASH_PLUS_PRODUCT_CARD: boolean;
  REQUIRE_COOKIEFIRST_CONSENT_MIXPANEL: boolean;
  NEW_QUOTE_FORM: boolean;
  QUOTE_FORM_LOMBARD_FIELDS: boolean;
  NEEDS_SANCTIONS_REVIEW: boolean;
  NEW_FPSP_POPUP: boolean;
  HOTJAR_TEST_EVENT: boolean;
  ENFORCE_TRAILING_SLASH_TO_URLS_AND_ROUTES: boolean;
  QUOTE_FORM_CAP_ON_TAP_FIELDS: boolean;
  WAIT_FOR_CONTINUATION_URL_ON_FPSP_MODAL: boolean;
  REQUESTED_AMOUNT_VS_ANNUAL_TURNOVER_CONFIRMATION_MODAL: boolean;
  FUNDING_CIRCLE_NORTHERN_IRELAND_ADDITIONAL_QUESTIONS: boolean;
  ITERABLE: boolean;
  REDIRECT_QUOTE_FORM_CUSTOMERS_TO_TIDE: boolean;
  SEGMENT_ACTIVE: boolean;
  KNOWLEDGE_PAGES_V2: boolean;
  LOGIN_REDIRECT_CONSOLE: boolean;
  NEW_TIDE_API_SERVICES: boolean;
  CONVERSION_PAGE_V2: boolean;
  MAINTENANCE_MODE: boolean;
  PAGE_BUILDER: boolean;
  CALCULATORS_V2: boolean;
  INVOICE_FINANCE_CALCULATOR: boolean;
  ASSET_FINANCE_CALCULATOR: boolean;
}

const flags: Flags = {
  BLOG_SEARCH: process.env.STAGE !== PRODUCTION,
  FUNDING_CIRCLE_NORTHERN_IRELAND_ADDITIONAL_QUESTIONS: true,
  CASHPLUS_QUESTIONS: true,
  BRS_NEW_USER_REDIRECT_AND_PREFILL: true,
  BRS_REFERRAL_COOKIE: true,
  DASHBOARD_ALL_APPLICATIONS: process.env.STAGE !== PRODUCTION,
  KNOWLEDGE_HUB_SEARCH: false,
  MODAL_BUSINESS_SERVICES_CTA: false,
  PARTNERS_USE_FORM: process.env.STAGE !== PRODUCTION && process.env.STAGE !== STAGING,
  PRODUCT_CARD_V2: true,
  PRODUCT_CARD_V2_MONTHLY_REPAYMENTS: false,
  QUOTE_FORM_PARTIAL_APPLICATIONS: process.env.STAGE !== PRODUCTION,
  QUOTE_FORM_PROPERTY_IS_KNOWN_ADDRESS: process.env.STAGE !== PRODUCTION && process.env.STAGE !== STAGING,
  SEND_VERIFY_EMAIL: false,
  FPSP_FIRST_TIME_LOADING: true,
  OPEN_BANKING_DROP_OFF_NOTIFICATION: process.env.STAGE !== PRODUCTION,
  OPEN_BANKING_MANDATORY_REGULAR_FLOW: true,
  OPEN_BANKING_MANDATORY_ON_QUERY_PARAM: true,
  OPEN_BANKING_LIGHT_AND_FULL_VIEWS: true,
  OPEN_ACCOUNTING: false,
  TIDE_CROSS_BUSINESS_SERVICES: true,
  TURNOVER_FIELD_UPDATE: true,
  ENABLE_BUSINESS_SERVICE_CARD: false,
  ENABLE_CASH_PLUS_PRODUCT_CARD: true,
  REQUIRE_COOKIEFIRST_CONSENT_MIXPANEL: true,
  QUOTE_FORM_LOMBARD_FIELDS: true,
  NEEDS_SANCTIONS_REVIEW: true,
  NEW_FPSP_POPUP: true,
  NEW_QUOTE_FORM: false,
  HOTJAR_TEST_EVENT: process.env.STAGE !== PRODUCTION,
  ENFORCE_TRAILING_SLASH_TO_URLS_AND_ROUTES: true,
  QUOTE_FORM_CAP_ON_TAP_FIELDS: true,
  WAIT_FOR_CONTINUATION_URL_ON_FPSP_MODAL: process.env.STAGE !== PRODUCTION,
  REQUESTED_AMOUNT_VS_ANNUAL_TURNOVER_CONFIRMATION_MODAL: true,
  ITERABLE: false,
  REDIRECT_QUOTE_FORM_CUSTOMERS_TO_TIDE: true,
  SEGMENT_ACTIVE: true,
  KNOWLEDGE_PAGES_V2: true,
  LOGIN_REDIRECT_CONSOLE: process.env.STAGE !== PRODUCTION,
  NEW_TIDE_API_SERVICES: true,
  CONVERSION_PAGE_V2: true,
  MAINTENANCE_MODE: false,
  PAGE_BUILDER: process.env.STAGE !== PRODUCTION,
  CALCULATORS_V2: process.env.STAGE !== PRODUCTION,
  INVOICE_FINANCE_CALCULATOR: true,
  ASSET_FINANCE_CALCULATOR: true,
};

export default flags;

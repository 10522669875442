import {PRODUCTION, STAGING} from './environments';

export const MINIMUM_REQUEST_AMOUNT = 1000;
export const MAXIMUM_REQUEST_AMOUNT = 15000000;
export const MINIMUM_NUMBER_OF_ASSETS = 1;
export const MINIMUM_NUMBER_OF_DIRECTORS = 1;
export const MAXIMUM_NUMBER_OF_DIRECTORS = 99;
export const MAXIMUM_NUMBER_OF_ASSETS = 10;
export const MINIMUM_NUMBER_OF_ASSETS_WITHIN_BUSINESS = 1;
export const MAXIMUM_NUMBER_OF_ASSETS_WITHIN_BUSINESS = 25000000;
export const MINIMUM_NET_COST_AMOUNT = 1000;
export const MAXIMUM_NET_COST_AMOUNT = 15000000;
export const MINIMUM_GROSS_COST_AMOUNT = 1000;
export const MAXIMUM_GROSS_COST_AMOUNT = 15000000;
export const REQUESTED_AMOUNT_LOWER_CAP = 25000;
export const SHOW_OB_REQUEST_AMOUNT_CAP = REQUESTED_AMOUNT_LOWER_CAP;
export const SOLE_TRADER_ZERO_OPTIONS_MAX_REQUESTED_AMOUNT = REQUESTED_AMOUNT_LOWER_CAP;
export const BLOG_ARTICLES_PER_PAGE = 9;
export const PRODUCTS_PER_PAGE = 8;
export const MULTI_PRODUCTS_PER_PAGE = 5; // DO NOT raise this number above 10 - it'll cause issues!!
export const PRESS_PAGE_QUOTES_PER_PAGE = 9;
export const SORT_SELECTED_PRODUCTS_ASCENDING = false;
export const SIGNUP_COOKIE_DISPLAY_TIMEOUT = process.env.STAGE === PRODUCTION ? 1000 * 10 : 1000;
export const MANDATORY_OPEN_BANKING_LIMIT = 25000;

// cookies
export const AFFID_COOKIE_NAME = 'affid';
export const SIGNUP_COOKIE_NAME = 'signupModal';
export const FOID_COOKIE_NAME = 'foid';
export const LANDING_URL_COOKIE_NAME = 'landingUrl';
export const UPDATES_STRIP_COOKIE_NAME = 'homepageUpdatesStrip';
export const COMPLIANCE_COOKIE_NAME = 'complianceConsent';
export const BUSINESS_SERVICES_COOKIE_NAME = 'businessServicesCta';
export const DIRECTORS_SUMMARY_COOKIE_NAME = 'directorsSummary';
export const BRS_REFERRER_URL_COOKIE_NAME = 'brsReferrer';
export const BRS_APPLICATION_REFERENCE_COOKIE_NAME = 'brsApp';

export const cookieDomain =
  process.env.STAGE === PRODUCTION
    ? // on prod, use '.fundingoptions.com' as the cookie domain, to support our whitelabelling (`abc.fundingoptions.com`)
      '.fundingoptions.com'
    : // All other cases should default to the current `location.host`, to prevent cookies bleeding between
      // our different environments.
      undefined;
export const hasSecureCookie = process.env.STAGE === PRODUCTION || process.env.STAGE === STAGING;

// LOCAL OR SESSION STORAGES
export const BRS_ORIGIN_URL = 'brsOriginUrl';

// events
export const UPDATE_USER_EVENT = 'identity.update';
export const UPDATE_APPLICATIONS_EVENT = 'user.applications.update';

// URIs
export const ADDRESS_LOOKUP_API = 'https://api.getAddress.io/find/';

// Errors
export const JWT_ERROR = 'Jwt token not provided';
export const AXIOS_CANCEL = 'axios.cancel';

// Date

export const DATE_FORMAT_DAY = 'dd';
export const DATE_FORMAT_MONTH = 'mm';
export const DATE_FORMAT_YEAR = 'yyyy';

export const WEBSITE_ROOT_URL = (() => {
  if (process.env.STAGE === PRODUCTION || process.env.STAGE === 'test') {
    return 'https://www.fundingoptions.com';
  }
  return 'https://staging.fops.dev';
})();

export const MIN_DIRECTORS_OWNERSHIP = 25;

// Open banking
export const OPEN_BANKING_CONNECTED_QUERY_PARAMETER = 'open_banking_connected';
export const FORCE_OPEN_BANKING_QUERY_PARAMETER = 'pcs_ob';
export const FORCE_OPEN_BANKING_MAXIMUM_REQUESTED_AMOUNT = 250_000;

// Hubspot
export const HUBSPOT_PORTAL_ID = '6463549';
export const HUBSPOT_SIGNUP_FORM_ID = '8ba82d23-f081-4c59-9412-cdf57fd15f91';
export const HUBSPOT_SIGNUP_FORM_ASSOCIATED_SUBSCRIPTIONS = 'Email signup';

// LOADING OPTIONS FIRST TIME SCREEN
export const LOADING_OPTIONS_FIRST_TIME_LOADING_QUERY_PARAMETER = 'first_load';
export const LOADING_OPTIONS_FIRST_TIME_TIMEOUT_IN_SECONDS =
  process.env.STAGE === PRODUCTION || process.env.STAGE === STAGING ? 25 : 5;
export const LOADING_OPTIONS_FIRST_TIME_FAKE_PROCESSING_RESULTS_TIME_IN_SECONDS = 2;
export const LOADING_OPTIONS_FIRST_TIME_UPDATE_INTERVAL_IN_SECONDS = 0.1;

// LOCALE SPECIC VALUES
export const CURRENCY_SYMBOL = '£';
export const COUNTRY_DIAL_CODE = '+44';
export const DEFAULT_LOCALE = 'en-GB';
export const EMAIL_LOCALE = 'en_EN';
export const LANGUAGE_LOCALE = 'en';
export const MARKET = 'UK';
export const NUMERIC_DECIMAL_SEPARATOR = '.';
export const NUMERIC_THOUSANDS_SEPARATOR = ',';

export const TIDE_WEB_APP_BASE_ADDRESS =
  process.env.STAGE === PRODUCTION ? 'https://web.tide.co' : 'https://web-wip.tide.co';

export const SHOULD_USE_FO_JOURNEY_PARAM_NAME = 'fo_journey';

export const TEST_TIDE_API = 'test_tide_api';

import {injectGlobal} from '@emotion/css';

export const cssVarsFonts = (): void => injectGlobal`
  :root {
    --font-primary: 'rubik', sans-serif;
    --font-secondary: 'radikal', sans-serif;
    --font-base-size: 1.6rem;
    --heading-line-height: 1.06;
    --font-base-line-height: 1.3;
    /* Weights */
    --font-weight-regular: 400;
    --font-weight-medium: 500;
    --font-weight-semi-bold: 600;
    /* Headers */
    --heading-one: 3.7rem;
    --heading-two: 3.2rem;
    --heading-three: 2.2rem;
    --heading-four: 1.7rem;
    --heading-five: 1.4rem;
    --heading-six: 1.2rem;
    /* Text */
    --font-size-XS: 1rem;
    --font-size-S: 1.2rem;
    --font-size-M: 1.4rem;
    --font-size-L: var(--font-base-size);
    --font-size-XL: 2rem;
    --font-size-XXL: 2.2rem;
    --font-size-XXX: 3.7rem;
    --font-size-hero-title: 4.9rem;
    /* Horizontal/text */
    --text-align-center: center;
    --text-align-left: left;
    --text-align-right: right;
  }
`;

export enum FontFamily {
  PRIMARY = 'var(--font-primary)',
  SECONDARY = 'var(--font-secondary)',
}

export enum FontSize {
  HERO_TITLE = 'var(--font-size-hero-title)',
  XXXL = 'var(--font-size-XXXL)',
  XXL = 'var(--font-size-XXL)',
  XL = 'var(--font-size-XL)',
  L = 'var(--font-size-L)',
  M = 'var(--font-size-M)',
  S = 'var(--font-size-S)',
  XS = 'var(--font-size-XS)',
  H1 = 'var(--heading-one)',
  H2 = 'var(--heading-two)',
  H3 = 'var(--heading-three)',
  H4 = 'var(--heading-four)',
  H5 = 'var(--heading-five)',
  H6 = 'var(--heading-six)',
}

export enum FontWeight {
  REGULAR = 'var(--font-weight-regular)',
  MEDIUM = 'var(--font-weight-medium)',
  SEMI_BOLD = 'var(--font-weight-semi-bold)',
}

export enum FontLineHeight {
  BASE = 'var(--font-base-line-height)',
  HEADING = 'var(--heading-line-height)',
}

export enum TextAlignment {
  INHERIT = 'inherit',
  LEFT = 'var(--text-align-left)',
  RIGHT = 'var(--text-align-right)',
  CENTER = 'var(--text-align-center)',
}

export const fonts = {
  family: FontFamily,
  size: FontSize,
  weight: FontWeight,
  lineHeight: FontLineHeight,
  alignment: TextAlignment,
};
